@import "variables";
@import "fonts";
@import "reset";
@import "breakpoints";

@import "layout";

@import "elements/elements";

// import partials css
@import "partials/header";
@import "partials/assets-static/hero-stage";
@import "partials/comparison-columns";
@import "partials/feature-show";
//@import "partials/carousel-quotes";
//@import "partials/trust-columns";
@import "partials/price";
@import "partials/steps";
@import "partials/accordion-container";
//@import "partials/core-promise";
@import "partials/footer";

// legal-notice and privacy-policy
@import "partials/legal-notice";
@import "partials/privacy-policy";

// dummy code

.dummy-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: steelblue;
}
