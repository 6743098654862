.steps {

  &__container {
    max-width: $preset-container-width;
    margin: auto;
    padding: $spacing-xxxl;

    @include upper-breakpoint-mobile {
      padding: $spacing-768-xxxl $spacing-768-xl;
    }
  }


  @include upper-breakpoint-mobile {
    &__button {
      margin-bottom: $spacing-768-m;
    }
  }

  &__headline {
    width: 100%;
    @include order1();
    text-align: center;
    padding: 0;
    margin: 0 0 $spacing-m;

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-l;
    }
  }

  &__copy {
    width: 80%;
    @include paragraph2(false);
    text-align: center;
    padding: 0;
    margin: 0 auto $spacing-xl;

    @include upper-breakpoint-mobile {
      width: 100%;
      margin: 0 0 $spacing-768-l;
    }
  }

  &__step-visual-container {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: $spacing-xl;
    @include row();
    align-items: center;

    @include upper-breakpoint-mobile {
      display: none;
    }
  }

  &__visual-number {
    position: absolute;

    font-family: assets-static/Poppins-ExtraBold, Helvetica;
    font-size: 56px;
    line-height: 79px;
    color: $color-screen;

    &:first-child {
      left: calc((100% / 6) - 11px);
    }
    &:nth-child(3) {
      left: calc(50% - 16px);
    }

    &:last-child {
      right: calc((100% / 6) - 17px);
    }

    &.only-mobile {
      display: none;
    }

    @include upper-breakpoint-mobile {
      position: relative;
      width: 100%;
      left: 0 !important;
      line-height: 48px;
      margin-bottom: $spacing-768-l;

      &.only-mobile {
        display: block;
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
        //align-self: flex-end;
      }

    }
  }

  &__visual-line {
    position: absolute;
    height: 2px;
    border: 1px solid $color-balance;

    &:nth-child(2) {
      left: calc((100% / 6) + 11px + #{$spacing-m});
      right: calc(50% + 16px + #{$spacing-m});
    }
    &:nth-child(4) {
      left: calc(50% + 16px + #{$spacing-m});
      right: calc((100% / 6) + 17px + #{$spacing-m});
      //border-color: red;
    }
  }
  &__steps-row {
    width: 100%;
    @include row();

    @include upper-breakpoint-mobile {
      flex-direction: column;
    }
  }

  &__step {
    width: calc(100% / 3);
    margin-right: $spacing-l;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }


    @include upper-breakpoint-mobile {
      width: 100%;
      margin: 0;
      display: grid;
      grid-template-columns: 88px 1fr;
    }
  }

  &__step-headline {
    @include paragraph1(true);
    padding: 0;
    margin: 0 0 $spacing-s;

    @include upper-breakpoint-mobile {
      @include paragraph1Variant(false);
      //width: 100%;
      text-align: left;
      //margin: 0 0 $spacing-768-m;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }

  }

  &__step-copy {
    @include paragraph2(false);

  }

}
