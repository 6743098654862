
$color-screen: #00D264;
$color-trust: #00453D;
$color-balance: #00965A;
$color-grey1: #404040;
$color-grey2: #808080;
$color-grey3: #A9A9A9;
$color-grey4: #D7D7D7;
$color-grey5: #F0F0F0;
$color-white: #fff;
$color-red: #fa766b;

$width-one-quarters: 25%;
$width-half: 50%;
$width-three-quarters: 75%;
$width-full: 100%;

$spacing-xxxs: 4px;
$spacing-xxs: 8px;
$spacing-xs: 12px;
$spacing-s: 16px;
$spacing-m: 24px;
$spacing-l: 36px;
$spacing-xl: 48px;
$spacing-xxl: 64px;
$spacing-xxxl: 88px;

$preset-container-width: 1024px;


$spacing-768-xxxs: 4px;
$spacing-768-xxs: 8px;
$spacing-768-xs: 12px;
$spacing-768-s: 16px;
$spacing-768-m: 24px;
$spacing-768-l: 32px;
$spacing-768-xl: 40px;
$spacing-768-xxl: 56px;
$spacing-768-xxxl: 64px;


