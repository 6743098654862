.legal-notice {
  max-width: $preset-container-width;
  margin: auto;
  padding: $spacing-xxxl;
  padding-right: 0;
  padding-left: 82px;

  @include upper-breakpoint-mobile {
    padding: $spacing-768-l;
  }

  &__container {
    width: $width-three-quarters;

    @include upper-breakpoint-mobile {
      width: $width-full;
    }
  }

  &__headline {
    margin: 0 0 $spacing-xl;
    @include order1();
    hyphens: auto;
  }

  &__copy {
    margin: 20px 0;
    @include paragraph2(false);
  }

  &__footnote {
    @include paragraph4();
    width: $width-three-quarters;

    @include upper-breakpoint-mobile {
      width: $width-full;
    }
  }

  a {
    text-decoration: none;
    color: $color-balance;
  }

  sup {
    vertical-align: text-bottom;
  }

  strong {
    @include paragraph2Variant(true);
  }
}


