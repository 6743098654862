.comparison {
  max-width: $preset-container-width;
  margin: auto;
  padding: $spacing-xxxl;

  @include upper-breakpoint-mobile {
    padding: $spacing-768-xxxl $spacing-768-l;
  }

  &__headline {
    width: 80%;
    margin: auto;
    margin-bottom: $spacing-xxl;
    @include order1();
    text-align: center;

    @include upper-breakpoint-mobile {
      width: 100%;
      margin: 0 0 $spacing-768-l;
      text-align: left;
    }
  }

  &__row {
    @include row();

    // maybe move into row
    @include upper-breakpoint-mobile {
      flex-direction: column;
    }
  }

  &__middle-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 33.33%;
    text-align: center;
    @include paragraph3();
    padding: 0 $spacing-xxl $spacing-l;

    @include upper-breakpoint-mobile {
      width: 100%;
      padding: 0;
      margin: 0 0 $spacing-768-xxxl;
    }
  }

  &__middle-column-chain {
    transform: scale(0.7) translateY(25px) translateX(3px);
  }

  &__middle-column-image {
    width: 140px;
    margin: $spacing-xxl 0;

    @include upper-breakpoint-mobile {
      margin: $spacing-768-l 0;
    }
  }

  &__middle-column-svg {
    height: 200px;

    @include upper-breakpoint-mobile {
      height: 100px;
    }
  }

  &__column-left {
    width: 33.33%;
    text-align: right;

    @include upper-breakpoint-mobile {
      width: 100%;
      padding: 0;
      text-align: left;
      margin: 0 0 $spacing-768-xxxl;
    }
  }

  &__column-right {
    width: 33.33%;
    text-align: left;


    @include upper-breakpoint-mobile {
      width: 100%;
      padding: 0;
    }
  }

  &__column-headline {
    padding: 0;
    margin: 0 0 $spacing-xxl;
    @include paragraph1(false);
    color: $color-screen;

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-l;

      @include paragraph1Variant();
      color: $color-screen;
    }
  }


  &__column-subheadline {
    display: block;
    padding: 0;
    margin: 0 0 $spacing-s;

    @include paragraph1();

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-s;
    }
  }

  &__column-copy {
    padding: 0;
    margin: 0 0 $spacing-l;
    @include paragraph2Variant(false);

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-l;
    }

    &:last-child {
      margin: 0;
    }

    span[title] {
      border-bottom: 1px dotted #000;
    }
  }

}
