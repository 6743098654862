.footer {

  max-width: $preset-container-width;
  margin: auto;
  padding-top: $spacing-xxl;

  @include upper-breakpoint-mobile {
    margin: 0;
    padding-top: $spacing-768-xxxl;
  }

  &__sub-container {
    @include row();
    justify-content: space-between;

    max-width: $preset-container-width;
    margin: auto;
    padding: 0 $spacing-xxxl $spacing-l $spacing-xl;

    @include upper-breakpoint-mobile {
      flex-direction: column;
      margin: 0;
      padding: 0 $spacing-768-l $spacing-768-m;
    }
  }

  &__logo {
    height: 40px;
    margin: $spacing-m $spacing-xl 0 0;

    @include upper-breakpoint-mobile {
      height: 33px;
      margin: 0 0 $spacing-768-l -1*$spacing-768-s;
      width: (130977 / 773) + 0px;
    }
  }

  &__contact {
    margin-right: $spacing-xxl;
    @include upper-breakpoint-mobile {
      margin: 0;
    }
  }

  @include upper-breakpoint-mobile {
    &__press {
      display: none;
    }
  }

  &__contact-headline,
  &__press-headline {
    display: block;
    @include paragraph2(true);
    margin-bottom: $spacing-m;

    @include upper-breakpoint-mobile {
      margin-bottom: $spacing-768-s;
    }
  }

  &__data-type {
    display: inline-block;
    margin-right: $spacing-s;
  }

  &__contact-data,
  &__press-data,
  &__press-download {
    margin-bottom: $spacing-s;
    @include paragraph2(false);

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      text-decoration: none;
      color: $color-grey1;
      &.footer__mail,
      &.footer__press-anchor {
        color: $color-balance;
      }
    }
  }

  &__press-download {
    @include paragraph2(false);
    color: $color-balance;
  }

  &__press-download-icon {
    width: 16px;
    height: 16px;
    margin-right: $spacing-xxxs;
  }

  &__grey-container {
    @include row();
    width: 100%;
    padding: 0 $spacing-xxxl;
    border-radius: 100px 0 0 0;
    background-color: $color-grey5;
    justify-content: space-between;

    @include upper-breakpoint-mobile {
      flex-direction: column;
      border-radius: 50px 0 0 0;
      padding: $spacing-768-xl $spacing-768-l;
    }
  }

  &__copyright {
    @include paragraph3();
    color: $color-grey3;
    padding: $spacing-l 0;

    @include upper-breakpoint-mobile {
      padding: 0;
    }
  }

  &__nav {
    @include row();
    margin: 0;
    padding: $spacing-l 0;
    list-style: none;

    @include upper-breakpoint-mobile {
      padding: $spacing-768-xl 0 0;
    }
  }

  &__nav-item {
    display: block;

    padding: 0;
    margin: 0 $spacing-l 0 0;

    @include upper-breakpoint-mobile {
      margin: 0 $spacing-768-m 0 0;
    }

    & > a {
      text-decoration: none;
      @include paragraph3();
      color: $color-balance;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
