$border-radius: 100px;
$border-radius-mobile: 50px;
$image-width-mobile: 190px;
$image-height-mobile: 385px;
$image-offset-mobile: 40px;
$headline-height-mobile:155px;


$image-width: 245px;
$image-height: 495px;
$slide-height: 620px;
$slide-height-mobile: 895px;
$controls-dots-width: 6 * $spacing-xxs + 5 * $spacing-xxs; // 6 dots with 5 spaces between them


.feature-show {
  position: relative;

  @include lower-breakpoint-mobile {
    max-width: $preset-container-width;
    margin: auto;
  }

  @include upper-breakpoint-mobile {
    width: 100%;
  }
  &__slide {
    position: relative;
    display: none;

    &--is-active {
      display: block;
    }
  }

  &__header-container {

    background-color: $color-screen;
    border-radius: $border-radius 0 $border-radius 0;

    padding: $spacing-l $spacing-xxxl ($border-radius + $spacing-l);

    @include upper-breakpoint-mobile {
      padding: 0 0 ($border-radius-mobile + $image-offset-mobile);
      border-radius: $border-radius-mobile 0 0 0;
    }
  }

  &__header {
    margin: 0;
    padding: 0 0 0 ($image-width + 2 * $spacing-xxl);

    @include order1();
    color: $color-white;

    @include upper-breakpoint-mobile {
      padding: $spacing-768-xl $spacing-768-l;
    }
  }

  &__body-container {
    position: relative;

    padding: 0 $spacing-xxxl;

    margin-top: -$border-radius;
    background-color: $color-grey5;
    border-radius: $border-radius 0 0 0;

    @include upper-breakpoint-mobile {
      border-radius: $border-radius-mobile 0 0 0;

      padding: 0 $spacing-768-l;
      margin-top: -($border-radius-mobile);
    }
  }

  @include upper-breakpoint-mobile {
    &__image-body {
      position: absolute;
      left: calc(50% - #{$image-width-mobile / 2});
      margin-top: -$image-offset-mobile;
    }

    &__text-body {
      padding-top: $image-height-mobile + $spacing-768-xxxl - $image-offset-mobile;
    }
  }


  &__image-body {
    width: $image-width;
    height: $image-height;
    //background-color: red;

    @include upper-breakpoint-mobile {
      width: $image-width-mobile;
      height: $image-height-mobile;
    }
  }

  &__image {
    position: absolute;
    top: 0;

    width: $image-width;
    height: $image-height;

    @include upper-breakpoint-mobile {
      width: $image-width-mobile;
      height: $image-height-mobile;
    }
  }

  @include lower-breakpoint-mobile {
    &__body {
      position: relative;

      max-width: $preset-container-width;
      min-height: $slide-height;
      margin: auto;
    }

    &__image-body {
      position: absolute;
      padding: 0 $spacing-xxl;
      margin-top: -150px
    }

    &__text-body {
      margin-left: 2 * $spacing-xxl + $image-width;
    }
  }


  @include upper-breakpoint-mobile {
    &__body {
      min-height: $slide-height-mobile;
    }
  }

    // special elements of feature body
  &__text-copy {
    @include paragraph2Variant(false);
    margin: 0;
    padding: $spacing-l 0 $spacing-m;

    @include upper-breakpoint-mobile {
      padding: 0 0 $spacing-768-l;
    }
  }

  &__text-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      display: flex;
      @include paragraph2(true);
      padding: 0 0 $spacing-m;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: $spacing-m;
    }
  }

  &__text-list-icon {
    min-width: 20px;
    width: 20px;
    height: 17px;
    margin-right: $spacing-xs;
  }

  &__controls-left {
    position: absolute;
    left: $spacing-l;
    //top: $spacing-xxl;
    top: 64px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: $color-white;


    @include upper-breakpoint-mobile {
      top: $headline-height-mobile + $image-height-mobile/ 2 - 25px;
      left: $spacing-768-s;
      color: $color-balance;
    }
  }

  &__controls-right {
    position: absolute;
    right: $spacing-l;
    //top: $spacing-xxl;
    top: 64px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: $color-white;

    @include upper-breakpoint-mobile {
      top: $headline-height-mobile + $image-height-mobile/ 2 - 25px;
      right: $spacing-768-s;
      color: $color-balance;
    }
  }

  &__controls-dots {
    position: absolute;
    bottom: $spacing-xxxl;
    left: calc(50% - #{$controls-dots-width/2});
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: $controls-dots-width;
    padding: 0;
    margin: 0;

    @include upper-breakpoint-mobile {
      top: $headline-height-mobile + $image-height-mobile + $spacing-768-s;
      bottom: unset;
    }
  }

  &__controls-dot {
    display: block;
    height: $spacing-xxs;
    width: $spacing-xxs;
    border-radius: $spacing-xxs/2;
    background-color: $color-grey2;

    &--is-active {
      background-color: $color-grey1;
    }
  }

  &__controls-next {
    position: absolute;
    left: calc(50% - #{$spacing-xxl});
    //left:  2 * $spacing-xxl + $image-width;
    bottom: $spacing-xxxl + $spacing-l + $spacing-xxs;

    @include upper-breakpoint-mobile {
      left: $spacing-768-l;
      right: $spacing-768-l;
      bottom: $spacing-768-xl;
      width: unset;
    }
  }

  .is-hidden {
    display: none;
  }
}


.fake-image {
  width: $image-width;
  height: $image-height;
  background-color: red;

  @include upper-breakpoint-mobile {
    width: $image-width-mobile;
    height: $image-height-mobile;
  }
}
