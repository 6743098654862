.privacy-policy {
  max-width: $preset-container-width;
  margin: auto;
  padding: $spacing-xxxl;
  padding-right: 0;
  padding-left: 82px;

  @include upper-breakpoint-mobile {
    padding: $spacing-768-l;
  }

  &__container {
    width: $width-three-quarters;

    @include upper-breakpoint-mobile {
      width: $width-full;
    }
  }

  &__section {
    margin: 60px 0;

    @include upper-breakpoint-mobile {
      margin: 40px 0;
    }
  }

  &__headline {
    margin: 0 0 $spacing-xl;
    margin-bottom: 24px;
    @include order1();
    hyphens: auto;
  }

  &__subheadline {
    display: block;
    padding: 0;
    margin: 0 0 $spacing-s;

    @include paragraph1();

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-s;
    }

    &--date {
      @include paragraph1(false);
      color: $color-screen;
      margin-bottom: 40px;
      text-transform: uppercase;

      @include upper-breakpoint-mobile {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__copy {
    margin: 20px 0;
    @include paragraph2(false);

    span {
      display: block;
      margin-top: 8px;
    }
  }

  &__list {
    margin: 20px 0;
    @include paragraph2(false);
    padding-left: 20px;
    width: 65%;

    @include upper-breakpoint-mobile {
      width: 100%;
    }

    li {
      margin-bottom: 8px;
      padding-left: 5px;
      
      &:last-child {
        margin-bottom: 0;
      }

      ul {
        margin-top: 7px;
      }
    }
  }

  a {
    text-decoration: none;
    color: $color-balance;
  }

  sup {
    vertical-align: text-bottom;
  }

  strong {
    @include paragraph2Variant(true);
  }
}


