@mixin order1() {
  font-family:  DocMorris-Regular, Helvetica;
  font-size: 44px;
  line-height: 53px;
  color: $color-trust;

  @include upper-breakpoint-mobile {
    font-size: 32px;
    line-height: 38px;
  }
}

@mixin order2() {
  font-family:  DocMorris-Regular, Helvetica;
  font-size: 32px;
  line-height: 38px;
  color: $color-trust;
}

@mixin order3($bold: true) {
  @if($bold) {
    font-family:  assets-static/Poppins-ExtraBold, Helvetica;
  } @else {
    font-family:  assets-static/Poppins-SemiBold, Helvetica;
  }
  font-size: 38px;
  line-height: 53px;
  color: $color-trust;
}

@mixin paragraph1($bold: true) {
  @if($bold) {
    font-family:  assets-static/Poppins-ExtraBold, Helvetica;
  } @else {
    font-family:  assets-static/Poppins-SemiBold, Helvetica;
  }
  font-size: 20px;
  line-height: 30px;
  color: $color-trust;

  @include upper-breakpoint-mobile {
    font-size: 20px;
    line-height: 23px;
  }
}

@mixin paragraph1Variant($bold: true) {
  @if($bold) {
    font-family:  assets-static/Poppins-ExtraBold, Helvetica;
  } @else {
    font-family:  assets-static/Poppins-SemiBold, Helvetica;
  }
  font-size: 16px;
  line-height: 22px;
  color: $color-grey1;
}



@mixin paragraph2($bold: true) {
  @if($bold) {
    font-family:  assets-static/Poppins-SemiBold, Helvetica;
  } @else {
    font-family:  assets-static/Poppins-Regular, Helvetica;
  }
  font-size: 15px;
  line-height: 23px;
  color: $color-grey1;

  @include upper-breakpoint-mobile {
    font-size: 13px;
    line-height: 18px;
  }
}


@mixin paragraph2Variant($bold: true) {
  @if($bold) {
    font-family:  assets-static/Poppins-SemiBold, Helvetica;
  } @else {
    font-family:  assets-static/Poppins-Regular, Helvetica;
  }
  font-size: 15px;
  line-height: 23px;
  color: $color-grey1;

  @include upper-breakpoint-mobile {
    font-size: 14px;
    line-height: 21px;
  }
}



@mixin paragraph3() {
  font-family:  assets-static/Poppins-Regular, Helvetica;
  font-size: 13px;
  line-height: 23px;
  color: #808080;
}


@mixin paragraph4() {
  font-family:  assets-static/Poppins-Regular, Helvetica;
  font-size: 10px;
  line-height: 17px;
  color: #808080;
}





@font-face {
  font-family: "DocMorris-Regular";
  src: url("../assets/fonts/DocMorris-Regular.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/DocMorris-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/DocMorris-Regular.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/DocMorris-Regular.svg") format("svg"), /* Legacy iOS */
  url("../assets/fonts/DocMorris-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/DocMorris-Regular.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/DocMorris-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "assets-static/Poppins-ExtraBold";
  src: url("../assets/fonts/assets-static/Poppins-ExtraBold.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/assets-static/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/assets-static/Poppins-ExtraBold.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/assets-static/Poppins-ExtraBold.svg") format("svg"), /* Legacy iOS */
  url("../assets/fonts/assets-static/Poppins-ExtraBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/assets-static/Poppins-ExtraBold.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/assets-static/Poppins-ExtraBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "assets-static/Poppins-Regular";
  src: url("../assets/fonts/assets-static/Poppins-Regular.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/assets-static/Poppins-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/assets-static/Poppins-Regular.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/assets-static/Poppins-Regular.svg") format("svg"), /* Legacy iOS */
  url("../assets/fonts/assets-static/Poppins-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/assets-static/Poppins-Regular.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/assets-static/Poppins-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "assets-static/Poppins-SemiBold";
  src: url("../assets/fonts/assets-static/Poppins-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/assets-static/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/assets-static/Poppins-SemiBold.otf") format("opentype"), /* Open Type Font */
  url("../assets/fonts/assets-static/Poppins-SemiBold.svg") format("svg"), /* Legacy iOS */
  url("../assets/fonts/assets-static/Poppins-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../assets/fonts/assets-static/Poppins-SemiBold.woff") format("woff"), /* Modern Browsers */
  url("../assets/fonts/assets-static/Poppins-SemiBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
