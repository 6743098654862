.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $color-white;
  z-index: 10;

  &__container {
    @include row();
    justify-content: space-between;
    align-items: center;
    height: 115px;
    max-width: $preset-container-width;
    margin: auto;
    padding: 0 $spacing-xl;

    @include upper-breakpoint-mobile {
      padding: 0;
    }
  }

  &__logo {
    height: 50px;
  }

  &__nav-container {
    @include row();
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-item {
    padding-right: $spacing-l;

    &:last-child {
      padding-right: 0;
    }

    & > a {
      @include paragraph2(false);
      text-decoration: none;
      color: $color-trust;

      &.is-external {
        @include paragraph2(true);
        color: $color-balance;
      }
    }
  }

  @include upper-breakpoint-mobile {
    position: relative;
    padding: $spacing-s $spacing-m $spacing-s $spacing-s;

    .hide-mobile {
      display: none;
    }

    &__container,
    &__logo {
      height: 33px;
    }
  }
}

html:not([data-scroll='0']) .header__container {
  height: 85px;
}
