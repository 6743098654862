

@mixin upper-breakpoint-mobile() {
    @media (max-width: 767px) {
      @content;
    }
}

@mixin lower-breakpoint-mobile() {
  @media (min-width: 768px) {
    @content;
  }
}
