.price {
  max-width: $preset-container-width;
  margin: auto;
  padding: $spacing-xxxl;

  @include upper-breakpoint-mobile {
    padding: $spacing-768-l;
  }

  &__container {
    @include row();

    @include upper-breakpoint-mobile {
      flex-direction: column;
    }
  }

  &__left-column {
    display: flex;
    //flex-grow: 1;
    flex-direction: column;
    width: 45%;
    //background-color: yellow;
    margin-right: $spacing-xl;


    @include upper-breakpoint-mobile {
      width: 100%;
    }
  }

  &__headline {
    margin: 0 0 $spacing-l;
    @include order1();
  }
  &__copy {
    flex-grow: 1;
    margin: 0;
    @include paragraph2(false);
  }
  &__footnote {
    @include paragraph4();

    &.only-mobile {
      display: none;
    }
    @include upper-breakpoint-mobile {
      display: none;

      &.only-mobile {
        display: block;
      }
    }
  }

  &__right-column {

    padding-bottom: $spacing-l;
    //flex-grow: 1;
    width: 55%;

    border: 4px solid $color-screen;
    border-radius: 0 0 50px 0;


    @include upper-breakpoint-mobile {
      width: 100%;
      margin: $spacing-768-xl 0;
    }
  }


  &__info-headline {
    background-color: $color-screen;
    border-radius: 0 0 50px 0;
    padding: $spacing-m $spacing-l;
    font-family: assets-static/Poppins-SemiBold, Helvetica;
    font-size: 24px;
    line-height: 33px;
    color: $color-white;
  }

  &__logo {
    height: 50px;
    margin: $spacing-l $spacing-l $spacing-m;
  }

  &__monthly-label {
    @include paragraph2Variant(false);
    margin: 0 0 -5px $spacing-xxl;

    @include upper-breakpoint-mobile {
      margin: 0 0 -5px $spacing-l;
    }
  }

  &__prices-value {
    margin: 0 0 0 $spacing-xxl;

    @include upper-breakpoint-mobile {
      margin: 0 0 0 $spacing-l;
    }
  }
  &__price-new {
    display: inline-block;
    font-family: assets-static/Poppins-ExtraBold;
    font-size: 56px;
    color: #141414;
    //letter-spacing: -1.4px;
  }
  &__price-cent {
    font-family: assets-static/Poppins-ExtraBold;
    font-size: 28px;
    color: #141414;
    vertical-align: top;
    line-height: $spacing-xxl;
  }
  &__price-strikethrough {
    display: inline-block;
    font-family: assets-static/Poppins-Regular;
    font-size: 28px;
    color: #808080;
    text-decoration: line-through;
    margin-left: $spacing-l;
    vertical-align: top;
    line-height: $spacing-xxl;
  }
  &__transaction-label {
    @include paragraph2Variant(false);
    margin: 0 0 5px $spacing-xxl;

    @include upper-breakpoint-mobile {
      margin: 0 0 5px $spacing-l;
    }
  }
  &__transaction-sublabel {
    @include paragraph2Variant(false);
    margin: 0 0 -5px $spacing-xxl;
    font-size: 10px;
    line-height: 13px;
    color: $color-grey2;

    @include upper-breakpoint-mobile {
      margin: 0 0 -5px $spacing-l;
    }
  }
  &__transaction-value {

    margin: 0 0 $spacing-m $spacing-xxl;
    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-m $spacing-l;
    }
  }
  &__percent {
    display: inline-block;
    font-family: assets-static/Poppins-ExtraBold;
    font-size: 56px;
    color: #141414;
  }
  &__percent-sign {
    font-family: assets-static/Poppins-ExtraBold;
    font-size: 28px;
    color: #141414;
    vertical-align: top;
    line-height: $spacing-xxl;
  }
  &__percent-strikethrough {
    display: inline-block;
    font-family: assets-static/Poppins-Regular;
    font-size: 28px;
    color: #808080;
    text-decoration: line-through;
    margin-left: 80px;
    vertical-align: top;
    line-height: $spacing-xxl;
  }
  &__info {

    @include paragraph2Variant(false);
    margin: 0 0 $spacing-m $spacing-xxl;

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-m $spacing-l;
    }
  }
  &__button {
    width: calc(100% - #{2 * $spacing-l});
    margin: 0 0 0 $spacing-l;

    font-family: assets-static/Poppins-SemiBold, Helvetica;
    font-size: 24px;
    line-height: 33px;
  }
}


