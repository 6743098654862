.button {
  display: inline-block;
  background: $color-balance;
  padding: $spacing-xs $spacing-l;
  text-align: center;
  border-radius: 30px 0 30px 30px;
  @include paragraph2();
  color: $color-white;
  cursor: pointer;
  text-decoration: none;

  @include upper-breakpoint-mobile {
    width: 100%;
  }
}

.button-inverted {
  display: inline-block;
  border: 2px solid $color-balance;
  padding: $spacing-xs $spacing-l;
  text-align: center;
  border-radius: 30px 0 30px 30px;
  @include paragraph2();
  color: $color-balance;
  cursor: pointer;
  text-decoration: none;

  @include upper-breakpoint-mobile {
    width: 100%;
  }
}
