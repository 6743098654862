.hero-stage {
  position: relative;
  @include row();
  max-width: $preset-container-width;
  margin: auto;
  padding: $spacing-xxl $spacing-xxxl;

  @include upper-breakpoint-mobile {
    padding: 100% $spacing-768-l $spacing-768-xxxl;
  }

  &__text-box {
    position: relative;
    width: 48%;
    z-index: 1;

    @include upper-breakpoint-mobile {
      width: 100%;
    }
  }

  &__headline {
    @include order1();
    color: $color-white;
    padding: 0;
    margin: 0 0 $spacing-xl;

    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-l;
    }
  }

  &__copy {
    @include paragraph2Variant(false);
    color: $color-white;
    padding: 0;
    margin: 0 0 $spacing-m;

    & ~ & {
      margin-bottom: $spacing-l;
    }


    @include upper-breakpoint-mobile {
      margin: 0 0 $spacing-768-m;

      & ~ & {
        margin-bottom: $spacing-768-l;
      }
    }
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    opacity: 0;
    border-radius: 100px 0 100px 0;
    object-fit: cover;
    object-position: right;
  
    @include upper-breakpoint-mobile {
      border-radius: 50px 0 50px 0;
      object-position: top;
    }

    &--is-active {
      opacity: 1;
    }
  }
}
