.accordion {


  &__headline {
    @include order1();
    margin: 0;
    padding: 0 0 $spacing-xxl;
    text-align: center;

    @include upper-breakpoint-mobile {
      padding: 0 $spacing-768-s $spacing-768-xl;
      text-align: left;
    }
  }

  &__container {
    max-width: $preset-container-width;
    margin: auto;
    padding: $spacing-xxl ($spacing-xxxl + $spacing-l);
    border-radius: 100px 0 0 0;
    background-color: $color-grey5;

    @include upper-breakpoint-mobile {
      width: 100%;
      margin: 0;
      padding: $spacing-768-xxxl $spacing-768-s;
      border-radius: 50px 0 0 0;
    }
  }
}

.accordion-element {
  @include row();
  padding: $spacing-m $spacing-m $spacing-m $spacing-xs;
  border-bottom: 1px solid $color-trust;
  cursor: pointer;


  @include upper-breakpoint-mobile {
    padding: $spacing-768-m $spacing-768-s;
  }

  &:nth-child(2) {
    padding-top: 0;
  }

  &__container {
    padding-left: $spacing-s;
  }

  &__title {
    @include paragraph2(false);
  }

  &--is-open {
    .accordion-element__title {
      @include paragraph2(true);
      padding-bottom: $spacing-m;
    }

    .accordion-element__body {
      display: block;
    }

  }

  &__body {
    display: none;
    @include paragraph2(false);

    @include upper-breakpoint-mobile {
      margin-left: -$spacing-l;
    }
  }

  &__icon--is-open {
    display: none;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &__icon--is-closed {
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &--is-open {
    .accordion-element__icon--is-open {
      display: block;
    }

    .accordion-element__icon--is-closed {
      display: none;
    }
  }
}
